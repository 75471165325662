<template>
    <div class="detail-container">
        <div class="detail_header">
            <van-nav-bar
                    title=""
                    left-text="取消"
                    @click-left="onCancel"/>
<!--            right-text="提交"-->
<!--            @click-right="onCommit"-->

        </div>
        <div class="detail_content">
            <div class="detail_cont_tit">考场规则</div>
            <div class="detail_cont_txt">
                考生须严格按照我校发布的《2022年美术特长生专业测试指南》的要求，做好考试场地、网络、设备、绘画工具等准备工作，诚信应考。
            </div>
            <div class="detail_cont_txt">
                <div>1.</div>
                <div>考生须按时登录考试系统，按照指引进行“考生报到”，至开考时间仍未进入考试系统报到者视为放弃考试。</div>
            </div>
            <div class="detail_cont_txt">
                <div>2.</div>
                <div>考试结束后，将三张试卷和打印签字的《2022年海淀区高中阶段艺术特长生承诺书》于28日当天通过快递邮寄，如有特殊情况，最迟不得晚于5月29日中午12点前寄出。邮寄时要求单人单件，使用一个快递单号，不得多名考生答题卷合并邮寄。如考生受疫情影响无法按时寄回试卷，请提供涉及疫情的相关证明。如无故且未在规定时间内寄回试卷，我校将按违纪处理，严重者将取消考试成绩。</div>
            </div>
            <div class="detail_cont_txt">
                <div>3.</div>
                <div>严禁考生弄虚作假、替考作弊，一经查实将按照《国家教育考试违规处理办法》进行处理。</div>
            </div>
            <div class="detail_cont_txt tit">
                如考生出现如下情况，将取消考试资格：
            </div>
            <div class="detail_cont_txt">
                <div>1.</div>
                <div>考生未能提供独立、安静、光线充足的房间作为考试房间。</div>
            </div>
            <div class="detail_cont_txt">
                <div>2.</div>
                <div>考生未按照规范设置考场或未能按照要求准备监控设备，且在监考老师提出质疑后不能立即按要求调整。</div>
            </div>
            <div class="detail_cont_txt">
                <div>3.</div>
                <div>考生未按照要求的时间及流程拆封考试试卷袋。</div>
            </div>
            <div class="detail_cont_txt tit">
                如考生违反下列要求，按照违规处理：
            </div>
            <div class="detail_cont_txt">
                <div>1.</div>
                <div>除用于监控的设备外，其他电子设备一律不得带入考场。考场内不得出现书籍、纸张（含草稿纸）、画稿、色卡、照片及任何形式的参考资料等；考试使用的画板或桌面须干净整洁，不得有文字、符号或图案。</div>
            </div>
            <div class="detail_cont_txt">
                <div>2.</div>
                <div>考生考前须按照流程要求，以考试座位为中心全面无死角展示考试环境，包含四周、地面和天花板。如考生使用的桌子带有桌斗或抽屉，桌斗或抽屉须面向墙壁。使用桌椅考试的考生考试过程双手均须在桌面可监控范围内，使用画板、画架类考生须保持双手双脚均在视频可监控范围内。</div>
            </div>
            <div class="detail_cont_txt">
                <div>3.</div>
                <div>色彩科目中的颜料仅限使用水粉或水彩，速写和素描科目仅限使用黑色铅笔或碳笔。卷面上不得出现任何与试题无关的标记。辅助工具仅限使用水桶、调色板、吹风机、橡皮、定画液、剪刀、图钉、胶带和黑色签字笔。</div>
            </div>
            <div class="detail_cont_txt">
                <div>4.</div>
                <div>考生不得佩戴墨镜、帽子、口罩和耳机，须在监控内素颜出镜，清晰地露出五官，长发考生须扎起头发，露出额头和耳朵，自带美颜的手机须关闭美颜功能。</div>
            </div>
            <div class="detail_cont_txt">
                <div>5.</div>
                <div>考生不得佩戴手表，可自行准备时钟放置于桌面或悬挂于监控范围内的墙壁上。</div>
            </div>
            <div class="detail_cont_txt">
                <div>6.</div>
                <div>考试期间，室内需保持安静。考生不得说话，不得播放音乐。</div>
            </div>
            <div class="detail_cont_txt">
                <div>7.</div>
                <div>考试过程中考生和试卷均须在视频监控范围内，考生不得将监控设备切出监控画面，双路视频监控均不得中断。</div>
            </div>
            <div class="detail_cont_txt">
                <div>8.</div>
                <div>考试结束时，考生应立即停笔，按要求在监控画面中进行试卷展示，对作品进行拍照上传。</div>
            </div>
            <div class="detail_cont_txt">
                <div>9.</div>
                <div>考生如有其他未按照本次专业考试要求进行的情形均界定为违规。我校将视违规情节严重程度进行相应处罚，严重者将直接取消考试成绩。</div>
            </div>
            <div class="detail_cont_txt tit">
                如考生违反下列要求，按照作弊处理：
            </div>
            <div class="detail_cont_txt">
                <div>1.</div>
                <div>考试过程中除考生本人外，任何其他人员不得在考场内出现。</div>
            </div>
            <div class="detail_cont_txt">
                <div>2.</div>
                <div>考生禁止以任何形式查阅资料，考生如有问题可点击监控中的【请求通话】按钮，等待监考老师联系，考生提问不得涉及考题相关内容。</div>
            </div>
            <div class="detail_cont_txt">
                <div>3.</div>
                <div>除监考老师外，考生禁止与任何其他人员交流。</div>
            </div>
            <div class="detail_cont_txt">
                <div>4.</div>
                <div>考生在考试期间禁止进食或使用洗手间。</div>
            </div>
            <div class="detail_cont_txt">
                <div>5.</div>
                <div>邮寄的试卷作品须与考生考试结束时上传的作品照片及视频录制中展示的作品完全一致。</div>
            </div>
            <div class="detail_cont_txt">
                <div>6.</div>
                <div>考试内容及试卷均属涉密事项，考场内所有设备均禁止开启蓝牙。考生不得以录屏、投屏、截屏、拍照等任何形式对考试题目进行保存或传播。除考试系统的监控外，考生不得以任何其他形式对考试过程进行录音或录像。</div>
            </div>
        </div>
        <div class="bottom-btn">
            <van-button @click="onCommit">我已阅读考场须知</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail",
        data() {
            return {}
        },
        created() {
            let is_xuzhi = localStorage.getItem("is_xuzhi");//须知
            debugger
            if(is_xuzhi == 1){
                this.$router.replace('/position');
            }
        },
        methods:{
            // 取消
            onCancel(){
                this.$router.replace('/position');
            },
            // 提交
            onCommit() {
                localStorage.setItem("is_xuzhi",1);
                this.$router.replace('/monitor');
            }
        }
    }
</script>

<style scoped lang="scss">
    .detail-container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-flow: column;

        .detail_header {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            background-color: #ffffff;
            z-index: 10;

            ::v-deep .van-nav-bar {
                .van-nav-bar__left {
                    .van-nav-bar__text {
                        color: #ee0a24;
                    }
                }

                .van-nav-bar__right {
                    .van-nav-bar__text {
                        color: #8b2d87;
                    }
                }

            }
        }

        .detail_content {
            flex: 1;
            width: 95%;
            margin: 48px auto 2%;
            border: 2px dashed #aaaaaa;
            padding: 0 10px 80px;
            background-color: #fff;
            font-family: "PingFang SC";

            .detail_cont_tit{
                font-size: 16px;
                text-align: center;
                line-height: 24px;
                padding: 15px 0;
                font-weight: 500;
                font-family: "PingFangSC-Regular";
            }
            .detail_cont_txt{
                font-size: 14px;
                text-align: left;
                line-height: 24px;
                /*text-indent: 2em;*/
                font-weight: 400;
                font-family: "PingFang SC";
                display: flex;
                flex-flow: row;
                div:nth-child(1){
                    margin-right: 5px;
                }
                &.tit {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    font-family: "PingFangSC-Regular";
                    margin: 10px 0 5px;
                }
            }
        }

        .bottom-btn{
            position: fixed;
            left: 0;
            bottom: 20px;
            height: 44px;
            width: 100%;
            padding: 0 16px;
            .van-button{
                width: 100%;
                background: #563279;
                border: 0;
                border-radius: 4px;
                color: #ffffff;
            }
        }
    }
</style>
